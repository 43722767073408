<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/profile" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            设置密码
          </h4>
        </div>
      </div>
    </div>

    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">
      <div class="section mt-2">
       

        <div class="card">
          <div class="card-body">
             <div class="alert alert-success mb-2" role="alert" v-if="isFlag">
          您已成功设置操作密码
        </div>
            <div class="p-1">
              <form>
                <div class="form-group basic animated">
                  <div class="input-wrapper">
                    <label class="label" for="payPsw">当前钱包</label>
                    <input :disabled="true" v-model="never_walletAddress" />
                  </div>
                </div>
                <div class="form-group basic animated">
                  <div class="input-wrapper">
                    <label class="label" for="payPsw">操作密码</label>
                    <input
                      :type="isopenpassword ? 'password' : 'text'"
                      id="payPsw"
                      :disabled="isFlag ? true : false"
                      v-model="payPsw"
                      placeholder="操作密码"
                    />
                    <div
                      class="set"
                      v-if="payPsw"
                      @click="isopenpassword = !isopenpassword"
                    >
                      <i class="fa fa-eye" v-if="isopenpassword"></i>
                      <i class="fa fa-eye-slash" v-else></i>
                    </div>
                  </div>
                </div>

                <div class="form-group basic animated">
                  <div class="input-wrapper">
                    <label class="label" for="confirmPayPsw">确认密码</label>
                    <input
                      :type="isopenconfirmPayPsw ? 'password' : 'text'"
                      :disabled="isFlag ? true : false"
                      v-model="confirmPayPsw"
                      id="confirmPayPsw"
                      placeholder="确认密码"
                    />

                    <div
                      class="set"
                      v-if="confirmPayPsw"
                      @click="isopenconfirmPayPsw = !isopenconfirmPayPsw"
                    >
                      <i class="fa fa-eye" v-if="isopenconfirmPayPsw"></i>
                      <i class="fa fa-eye-slash" v-else></i>
                      <!-- <i class="fa fa-close" @click="confirmPayPsw = ''">
                      </i> -->
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <button
                    v-if="!isFlag"
                    type="button"
                    @click="savePayPsw()"
                    class="btn btn-primary btn-lg btn-block"
                  >
                    保存
                  </button>
                  <button
                    v-if="isFlag"
                    type="button"
                    disabled
                    style="font-size:16px"
                    class="btn btn-primary btn-lg btn-block"
                  >
                    已设置操作密码
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      

    </div>
   
 
  </div>
</template>

<script>
export default {
  data() {
    return {
      objConfig: _GlobalConfig[_GlobalConfig.currentEnv],
      oldPayPsw: "",
      payPsw: "",
      confirmPayPsw: "",
      isopenpassword: true,
      isoldPayPsw: true,
      isopenconfirmPayPsw: true,
      isFlag: false,
      edit: 0,
      never_walletAddress: "",
    };
  },
  components: {},
  created() {
    var self = this;
    this.checkPayPswFn();
    this.never_walletAddress = localStorage.getItem("never_walletAddress");
  },
  methods: {
    savePayPsw() {
      if (!this.payPsw || !this.confirmPayPsw) {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.payPsw.length < 8 || this.confirmPayPsw.length < 8) {
        this.$message.error("请输入8位数以上的字符");
        return;
      }
      const check =
        /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/;
      if (check.test(this.payPsw)) {
        console.log("ok");
      } else {
        this.$message.error(
          "操作密码由8位以上的英文字母（大小写）、数字、特殊字符组成"
        );

        return;
      }

      if (this.payPsw != this.confirmPayPsw) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      let self = this;
      let obj = {
        walletAddress: localStorage.getItem("never_walletAddress") || "-",
        psw: self.payPsw,
      };

      self.axios
        .post(self.api.savePayPsw, obj)
        .then((res) => {
         
         
          if (res.flag) {
            self.payPsw = "";
          self.confirmPayPsw = "";
          self.checkPayPswFn();
            this.$message.success(" 恭喜您! 您已成功设置操作密码!");
          }else{
            this.$message.success(res.message);

          }
        });
    },
    checkPayPswFn() {
      var self = this;
      this.axios
        .get(this.api.indexApi, {
          params: {
            walletAddress: localStorage.getItem("never_walletAddress") || "-",
          },
        })
        .then((res) => {
          this.isFlag = res.data.user.hasPsw;
          // this.isFlag = true;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.card-body {
  margin-top: 50px;
}
.input-wrapper {
  position: relative;
}
.set {
  top: 40px;
  position: absolute;
  right: 20px;
  i {
    margin-left: 25px;
    font-size: 16px;
  }
}
.idetIcon {
  width: 24px;
}
.tipMt {
  margin: 20px;
  li {
    margin-top: 20px;
  }
}
</style>
