<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/onlineProblem" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            我的工单列表
          </h4>
        </div>
      </div>
    </div>

    <div class="tabs-page segments-page">
      <div class="container">
        <div>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-tabs1"
              role="tabpanel"
              aria-labelledby="nav-tabs1-tab"
            >
              <table
                class="table table-default b-shadow onlineProblemList"
                v-for="(item, idx) in getTrackList"
                :key="idx"
              >
                <tbody>
                   <tr>
                    <td>工单序号</td>
                    <td>{{idx+1}}</td>
                  </tr>
                  <tr>
                    <td>工单类型</td>
                    <td>
                      <span v-if="item.type == 1">账户问题</span>
                      <span v-if="item.type == 2">上架问题</span>
                      <span v-if="item.type == 3">充值问题</span>
                      <span v-if="item.type == 4">迁移问题</span>
                    </td>
                  </tr>
                  <tr>
                    <td>工单标题</td>
                    <td>{{ item.title }}</td>
                  </tr>
                  <tr>
                    <td>工单内容</td>
                    <td style="color: #00A3A9" @click="content=item.content;alertBackTips=true">查看</td>
                  </tr>
                  <tr>
                    <td>工单状态</td>
                    <td>
                      <span v-if="item.status == 0" style="color: red"
                        >未处理</span
                      >

                      <span v-else style="color: green">已处理</span>
                    </td>
                  </tr>
                  <tr>
                    <td>添加时间</td>
                    <td>{{ item.addTime }}</td>
                  </tr>
                 
                </tbody>
              </table>

              <el-empty
                v-if="!getTrackList.length"
                description="暂无工单列表"
              ></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end contact -->

       <div class="alertBackTips" v-if="alertBackTips">
      >
      <div class="tipCom b-shadow">
        <div class="tipTitle">工单内容</div>
        <div class="ovsList" v-html="content">
         
        
        </div>
        <div class="colseBtn" @click="alertBackTips = false">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  data() {
    return {
      walletAddress: "",
      getTrackList: [],
      content:'',
      alertBackTips:false
    };
  },
  components: {},
  mounted() {
    if (localStorage.getItem("never_walletAddress")) {
      this.walletAddress = localStorage.getItem("never_walletAddress");
      this.getTrackListFn();
    }
  },
  methods: {
    getTrackListFn() {
      this.axios
        .get(this.api.getTrackList, {
          params: {
            walletAddress: localStorage.getItem("never_walletAddress"),
          },
        })
        .then((res) => {
          if (res.flag) {
            this.getTrackList = res.data;
          } else {
          }
        });
    },
  },
  created() {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
.tab-content {
  margin-top: 0;
}
.onlineProblemList {
  color: #666;
  margin-bottom: 20px;
  background: #fff;
}
.onlineProblemList td:nth-child(2) {
  text-align: right;
}
.alertBackTips {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .tipCom {
    background: #fff;
    width: 80%;
    margin: 0 auto;
    padding: 16px;
    padding-right: 0;
    border-radius: 8px;
  }
  .tipTitle {
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: 600;
  }
  .ovsList {
    max-height: 480px;
    overflow-y: scroll;
    margin-top: 10px;
    padding-right: 16px;
    margin: 20px 0;
    > div {
      color: #333;
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 1.6;
    }
  }

  .colseBtn {
    border: 1px solid #333;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 16px;
  }
}
</style>
