/**
 * Created by superman on 17/2/16.
 * http配置
 */
import Vue from "vue";
import axios from "axios";
import store from "../store/store";
import * as types from "../store/types";
import router from "../router/index";

// 測試
Vue.prototype.networdStatusAll = 97;

// 正式
// Vue.prototype.networdStatusAll = 56;

// axios 配置
axios.defaults.timeout = 12600000
window._GlobalConfig = {
  currentEnv: 'pro',//test pro local

  /* local */
  local: {
    projectName: 'NGIDC',
    projectInstanceName: 'NGIDC',
    website: 'http://localhost:8100',
    apiDomain: 'http://localhost:8100',

  },

  /* test */
  test: {
    projectName: 'NGIDC',
    projectInstanceName: 'NGIDC',
    website: 'http://3.36.136.238:18100',
    apiDomain: 'http://3.36.136.238:18100',

  },

  /* pro */
  pro: {
    projectName: 'NGIDC',
    projectInstanceName: 'NGIDC',
    website: 'https://data.ngidc.net',
    apiDomain: 'https://data.ngidc.net',

  },
}


import Vconsole from 'vconsole'
if (window._GlobalConfig.currentEnv == 'test') {
  let vConsole = new Vconsole()
  Vue.use(vConsole)
}

axios.defaults.baseURL = _GlobalConfig[_GlobalConfig.currentEnv].apiDomain

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    config.headers["Lang"] = localStorage.getItem("lang") || "zh_CN";

    var token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    var lang = localStorage.getItem("lang")
    if (lang == '' || lang == null) {
      lang = "zh-CNS"
    }


    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {


    return response.data;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 401 清除token信息并跳转到登录页面
          store.commit(types.LOGOUT);
          // 只有在当前路由不是登录页面才跳转
          router.currentRoute.path !== "login" &&
            router.replace({
              path: "login",
              query: { redirect: router.currentRoute.path },
            });
      }
    }
    return Promise.reject(error.response.data);
  }
);

export default axios;


