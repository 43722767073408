<template>
  <div id="app">
    <div class="wrap-preloader">
        <div class="preloader">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <router-view />
     <!-- loader -->
  
  </div>
</template>
<script>
export default {
  mounted() {
    var self = this;
},
  methods: {
    
  },
};
</script>

<style lang="less">

* {
  margin: 0;
  padding: 0;
  font-family: "Source Han Sans SC";
}
.el-message{
  min-width: 80% !important;
}
.el-message-box{
  width: 80% !important
}
.el-button--primary{
  background: #00A3A9 !important;
  border-color: #00A3A9 !important;
}
.btn-primary{
   background: #00A3A9 !important;
  border-color: #00A3A9 !important;

}
h4{
  font-size: 14px !important;
}
</style>
