<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/profile" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            申请下架设备
          </h4>
        </div>
      </div>
    </div>
    <!-- end navbar -->


    <!-- contact -->
    <div class="contact segments-page">
      <div class="container">

        <div class="alert alert-danger" role="alert">
          申请注意事项:
          <br />
          1. 请正确填写下架申请资料。
          <br />
          2. 请正确填写快递寄送资料。
          <br />
          3. 当您申请成功后请在首页"申请下架"列表查看。
          <br />
          4. 请您到香港机房自提，提前做好海关的报关准备。
          <br />
          5. 最后，您的资料将会通过服务公司或社区核对，可能将会通过电话确认您的资料，请注意接听。
        </div>

        <div class="contact-contents b-shadow">

          <form>
            <p style="font-weight: bold; text-align: left;">申请资料:</p>
            <input
              type="text"
              placeholder="姓名"
              name="realName"
              id="realName"
              v-model="realName"
            />
            <input
              type="email"
              placeholder="设备钱包地址"
              name="walletAddress"
              disabled
              id="walletAddress"
              v-model="walletAddress"
            />
            <input
              type="number"
              placeholder="手机号码"
              name="phoneNumber"
              id="phoneNumber"
              v-model="phoneNumber"
            />
            <textarea
              cols="30"
              rows="8"
              placeholder="申请下架原因"
              name="reason"
              id="reason"
              maxlength="500"
              v-model="reason"
            ></textarea>
            <p style="font-weight: bold; text-align: left;">快递资料:</p>
            <input
              type="text"
              placeholder="收件人姓名"
              name="receiveRealName"
              id="receiveRealName"
              v-model="receiveRealName"
            />
            <input
              type="number"
              placeholder="收件人手机"
              name="receivePhoneNumber"
              id="receivePhoneNumber"
              v-model="receivePhoneNumber"
            />
            <input
              type="text"
              placeholder="收件人地址"
              name="receiveAddress"
              id="receiveAddress"
              v-model="receiveAddress"
            />
            <el-button @click="applyOffline" type="primary" class="btn" :loading="loadingBtn">
              确认申请
            </el-button>
          </form>

        </div>
      </div>
    </div>
    <!-- end contact -->
  </div>
</template>

<script>
export default {
  name: 'profile',
  data() {
    return {
      realName: '',
      walletAddress: '',
      phoneNumber: '',
      reason: '',
      receiveRealName: '',
      receivePhoneNumber: '',
      receiveAddress: '',
      loadingBtn: false,
    }
  },
  components: {},
  mounted() {
    if (localStorage.getItem('never_walletAddress')) {
      this.walletAddress = localStorage.getItem('never_walletAddress')
    }
  },
  methods: {
    applyOffline() {
      if (!this.realName) {
        this.$message({
          message: '请输入姓名',
          type: 'warning',
        })
        return
      }
      if (!this.walletAddress) {
        this.$message({
          message: '请输入设备钱包地址',
          type: 'warning',
        })
        return
      }
      if (!this.phoneNumber) {
        this.$message({
          message: '请输入手机号码',
          type: 'warning',
        })
        return
      }
      let obj = this.isValidateNoneMobile(this.phoneNumber)
      if (!obj.result) {
        this.$message({
          message: obj.msg,
          type: 'warning',
        })
        return
      }

      if (!this.reason) {
        this.$message({
          message: '请输入申请下架原因',
          type: 'warning',
        })
        return
      }
      if (!this.receiveRealName) {
        this.$message({
          message: '请输入收件人姓名',
          type: 'warning',
        })
        return
      }
      if (!this.receivePhoneNumber) {
        this.$message({
          message: '请输入收件人手机',
          type: 'warning',
        })
        return
      }
      let obj2 = this.isValidateNoneMobile(this.receivePhoneNumber)
      if (!obj2.result) {
        this.$message({
          message: obj2.msg,
          type: 'warning',
        })
        return
      }
      if (!this.receiveAddress) {
        this.$message({
          message: '请输入收件人地址',
          type: 'warning',
        })
        return
      }
      this.loadingBtn = true
      this.axios
        .post(this.api.applyOffline, {
          realName: this.realName,
          walletAddress: this.walletAddress,
          phoneNumber: this.phoneNumber,
          reason: this.reason,
          receiveRealName: this.receiveRealName,
          receivePhoneNumber: this.receivePhoneNumber,
          receiveAddress: this.receiveAddress,
        })
        .then((res) => {
          this.loadingBtn = false

          if (res.flag) {
            this.realName = ''
            this.walletAddress = ''
            this.phoneNumber = ''
            this.reason = ''
            this.receiveRealName = ''
            this.receivePhoneNumber = ''
            this.receiveAddress = ''
            this.$message({
              message: '操作成功',
              type: 'warning',
            })
          } else {
            this.$message.error(res.message)
          }
        })
    },

    /**
     * 判断手机号码是否正确
     */
    isValidateNoneMobile(phone) {
      const obj = {
        result: true,
        msg: '',
      }

      var isPhone = /^0\d{2,3}-?\d{7,8}$/
      if (phone) {
        if (phone.length === 11) {
          if (isPhone.test(phone)) {
            obj.msg = '手机号码格式不正确'
            obj.result = false
          }
        } else {
          obj.msg = '手机号码长度不为11位'
          obj.result = false
        }

        if (isPhone.test(phone)) {
          obj.msg = '手机号码格式不正确'
          obj.result = false
        }
      } else {
        obj.msg = '手机号码不能为空'
        obj.result = false
      }

      return obj
    },
  },
  created() {},
  destroyed() {},
}
</script>
<style lang="less" scoped>
.btn {
  width: 100%;
}
</style>
