<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/profile" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            支付代付订单
          </h4>
        </div>
      </div>
    </div>
    <!-- end navbar -->

    <!-- contact -->
    <div class="contact segments-page">
      <div class="container">
        <div class="contact-contents b-shadow">
          <div class="contact-info" style="color: red; margin-top: 5px;">
            <p>支付操作步骤：</p>
            <p>1. 请您核对代付订单总金额</p>
            <p>2. 请您尽快通过BSC-USDT支付订单</p>
          </div>
        </div>
      </div>
    </div>
    <!-- end contact  -->

    <div class="wrap-card">
      <div class="container">
        <div class="wrap-title">
          <h5>代付收款钱包:</h5>
        </div>
        <div class="card b-shadow">
          <div id="qrcode2"></div>
          <div class="card-body text-center">
            <h5 class="card-title">套餐续费收款钱包地址:</h5>
            <p class="card-text">{{ resData.payWalletAddress }}</p>
            <button
              class="button waves-effect blue b-shadow"
              @click="copySubmit(resData.payWalletAddress)"
              style="background-color: #28a745;"
            >
              复制钱包地址
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="table-page">
      <div class="container">
        <div class="wrap-title">
          <h5>代付订单资料:</h5>
        </div>
        <div class="wrap-content b-shadow">
          <table class="table table-default">
            <thead>
              <tr>
                <th>代付订单号</th>
                <th @click="copySubmit(resData.orderNumber)">
                  {{ resData.orderNumber }}
                </th>
                <!--点击可以复制-->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>代付总设备数</td>
                <td>{{ resData.amount || '-' }}</td>
              </tr>
              <tr>
                <td>代付套餐类型</td>
                <td>{{ resData.planType || '-' }}</td>
              </tr>
              <tr>
                <td>代付总金额</td>
                <td class="setRed">{{ resData.money || '-' }} USDT</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  name: '',
  data() {
    return {
      resData: {},
    }
  },
  components: { QRCode },

  mounted() {
    this.resData = JSON.parse(this.$route.query.data)
    console.log(this.resData)
    this.qrcode()
  },
  methods: {
    //  生成二维码
    qrcode() {
      let that = this
      $('#qrcode2').html('')
      let qrcode = new QRCode('qrcode2', {
        width: 160,
        height: 160,
        text: this.resData.payWalletAddress || 'No Address',
      })
    },
  },
  created() {},
  destroyed() {},
}
</script>
<style lang="less" scoped>
.btn {
  width: 100%;
  margin-bottom: 10px;
}
#qrcode {
  margin: 0 auto;
}
.setRed{
  color: red;
  font-size: 18px;
}
</style>
