export default {
 
  indexApi: "/data/api/index",
  applyOffline: "/data/api/applyOffline",
  planList: "/data/api/planList",
  batchPay: "/data/api/batchPay",
  queryDevice: "/data/api/queryDevice",   
  batchPayList: "/data/api/batchPayList",   
  postMigrateApply: "/data/api/postMigrateApply",   
  savePayPsw: "/data/api/savePsw",

  saveTrack: "/data/api/saveTrack",
  getTrackList: "/data/api/getTrackList",
  saveVisitApply: "/data/api/saveVisitApply",
  getVisitApplyList: "/data/api/getVisitApplyList",
  saveDeviceApply: "/data/api/saveDeviceApply",
  getDeviceApplyStatus: "/data/api/getDeviceApplyStatus",


};
