const goTo = function dateFormat(url) {
  this.$router.push(url);
};
const goRep = function dateFormat(url) {
  this.$router.replace(url);
};
const goBack = function dateFormat(num) {
  this.$router.go(num);
};

const goResolve = function dateFormat(num) {
  window.open(this.$router.resolve(num).href, "_blank");
  // window.open(num,'_blank')
};

const goHref = function dateFormat(url) {
  if(!url){
    return
  }
  window.open(url, "_blank");
};
export { goTo, goRep, goBack, goResolve ,goHref};
