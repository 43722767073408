<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/profile" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            申请兑换GBox
          </h4>
        </div>
      </div>
    </div>
    <!-- end navbar -->

    <!-- contact -->
    <div class="contact segments-page">
      <div class="container" >
        <div class="alert alert-danger" role="alert">
          <p>申请状态：{{detail.hasDeviceApply?'已申请':'未申请'}}</p>
          <p>您的套餐日期：{{ detail.yourPlanEndDate }}</p>
          <p>套餐达标日期：{{ detail.validPlanEndDate }}</p>
          <p>截止申请日期：{{ detail.endApplyDate }}</p>
        </div>
        <div class="contact-contents b-shadow">
          <form>
            <!-- <p style="font-weight: bold; text-align: left;">申请资料:</p> -->
            <input
              type="text"
              placeholder="钱包地址"
              name="walletAddress"
              id="walletAddress"
              disabled
              v-model="walletAddress"
            />
            <input
              type="text"
              placeholder="GBox开机钱包地址"
              name="gboxWalletAddress"
              id="gboxWalletAddress"
              v-model="gboxWalletAddress"
            />
            <select
              class="form-control custom-select"
              id="type"
              @change="iptpriceFn"
              v-model="countryId"
              name="type"
            >
              <option value="-1" hidden>地区</option>
              <option value="1">HK-香港</option>
              <option value="2">Laos-老挝</option>
              <option value="3">Japan-日本</option>
              <option value="4">Korea-韩国</option>
              <option value="5">Cambodia-柬埔寨</option>
              <option value="6">Malaysia-马来西亚</option>
              <option value="7">Samoa-萨摩亚</option>
              <option value="8">UK-英国</option>
              <!-- <option value="16">UK2-英国</option> -->
              <option value="9">Vietnam-越南</option>
              <option value="10">Singapore-新加坡</option>
              <option value="11">Indonesia-印尼</option>
              <option value="12">Australia-澳洲</option>
              <option value="13">Russia-俄罗斯</option>
              <option value="15">India-印度</option>
              <option value="14">Dubai-迪拜</option>
            </select>
            <input
              type="text"
              placeholder="姓名"
              name="realname"
              id="realname"
              v-model="realname"
            />
            <input
              type="number"
              placeholder="手机"
              name="phone"
              id="phone"
              v-model="phone"
            />
            <textarea
              type="text"
              placeholder="备注"
              name="applyNote"
              id="applyNote"
              v-model="applyNote"
            />

            <input
              type="password"
              placeholder="操作密码"
              name="psw"
              id="psw"
              v-model="psw"
            />
            <el-button
              @click="applyOffline"
              type="primary"
              class="btn"
              :loading="loadingBtn"
            >
              提交申请
            </el-button>
          </form>
        </div>
        
      </div>
  
    </div>
    <!-- end contact -->
  </div>
</template>

<script>
export default {
  name: "profile",
  data() {
    return {
      walletAddress: "",
      // snNumber: "",
      realname: "",
      phone: "",
      psw: "",
      club: "-1",
      loadingBtn: false,
      gboxWalletAddress: "",
      countryId: "-1",
      applyNote: "",
      detail: {},
    };
  },
  components: {},
  mounted() {
    if (localStorage.getItem("never_walletAddress")) {
      this.walletAddress = localStorage.getItem("never_walletAddress");
    }
    this.indexApi();
    this.getDeviceApplyStatus();
  },
  methods: {
    getDeviceApplyStatus() {
      this.axios
        .get(this.api.getDeviceApplyStatus, {
          params: {
            walletAddress: localStorage.getItem("never_walletAddress"),
          },
        })
        .then((res) => {
          if (res.flag) {
            this.detail = res.data;
          }
        });
    },
    indexApi() {
      this.axios
        .get(this.api.indexApi, {
          params: {
            walletAddress: localStorage.getItem("never_walletAddress"),
          },
        })
        .then((res) => {
          if (res.flag) {
            let user = res.data.user;
            if (!user.hasPsw) {
              this.$confirm("您尚未设置操作密码 ,请前往设置!", "操作密码", {
                confirmButtonText: "立刻设置",
                cancelButtonText: "关闭",
                type: "warning",
              })
                .then(() => {
                  this.goTo("/setPayPsw");
                })
                .catch(() => {});
            }
          }
        });
    },
    iptpriceFn() {},
    applyOffline() {
      if (this.gboxWalletAddress==this.walletAddress) {
        this.$message({
          message: "您的钱包地址不能和GBox开机钱包地址相同",
          type: "warning",
        });
        return;
      }

      if (!this.gboxWalletAddress) {
        this.$message({
          message: "请输入GBox开机钱包地址",
          type: "warning",
        });
        return;
      }

      if (!this.realname) {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      }
      if (!this.phone) {
        this.$message({
          message: "请输入电话",
          type: "warning",
        });
        return;
      }
      if (!this.countryId || this.countryId == -1) {
        this.$message({
          message: "请选择地区",
          type: "warning",
        });
        return;
      }

      let obj = this.isValidateNoneMobile(this.phone);
      if (!obj.result) {
        this.$message({
          message: obj.msg,
          type: "warning",
        });
        return;
      }

      if (!this.psw) {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.psw.length < 8) {
        this.$message.error("请输入8位数以上的字符");
        return;
      }
      const check =
        /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/;
      if (check.test(this.psw)) {
      } else {
        this.$message.error(
          "操作密码由8位以上的英文字母（大小写）、数字、特殊字符组成"
        );

        return;
      }

      this.loadingBtn = true;
      this.axios
        .post(this.api.saveDeviceApply, {
          gboxWalletAddress: this.gboxWalletAddress,
          name: this.realname,
          phone: this.phone,
          countryId: this.countryId,
          walletAddress: this.walletAddress,
          applyNote: this.applyNote,
          psw: this.psw,
        })
        .then((res) => {
          this.loadingBtn = false;

          if (res.flag) {
            this.countryId = -1;
            this.applyNote = "";
            this.gboxWalletAddress = "";
            this.phone = "";
            this.realname = "";
            this.psw = "";
            this.$message({
              message: "申请兑换提交成功",
              type: "warning",
            });
            this.$router.go(-1);
          } else {
            this.$message.error(res.message);
          }
        });
    },

    /**
     * 判断手机号码是否正确
     */
    isValidateNoneMobile(phone) {
      const obj = {
        result: true,
        msg: "",
      };

      var isPhone = /^0\d{2,3}-?\d{7,8}$/;
      if (phone) {
        if (phone.length === 11) {
          if (isPhone.test(phone)) {
            obj.msg = "手机号码格式不正确";
            obj.result = false;
          }
        } else {
          obj.msg = "手机号码长度不为11位";
          obj.result = false;
        }

        if (isPhone.test(phone)) {
          obj.msg = "手机号码格式不正确";
          obj.result = false;
        }
      } else {
        obj.msg = "手机号码不能为空";
        obj.result = false;
      }

      return obj;
    },
  },
  created() {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
.btn {
  width: 100%;
  margin-top: 20px;
}
form select {
  font-size: 14px !important;
  width: 100%;
  background: #eee;
  color: #888;
  border: 0;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 10px;
}
form select:focus {
  border-bottom: 0 !important;
}
input {
  font-size: 13px !important;
}
</style>
