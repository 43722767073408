<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/profile" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            批量代付设备
          </h4>
        </div>
      </div>
    </div>
    <!-- end navbar -->

    <!-- contact -->
    <div class="contact segments-page">
      <div class="container">
        <div class="contact-contents b-shadow">
          <div class="contact-info" style="color: red; margin-top: 5px;">
            <p>批量代付操作步骤: </p>
            <p>1. 请正确填写多个需要被代付的设备钱包地址</p>
            <p>2. 请查询核对需要被代付的设备信息</p>
            <p>3. 请选择批量代付的套餐类型</p>
            <p>4. 请您向本次代付的收款钱包地址转入所需的BSC-USDT数量</p>
            <p>5. 请您等待交易上链后，代付订单将会自动完成</p>
            <p>6. 请您切换被代付的某个账号，检查是否该设备已被代付成功</p>
          </div>
          <hr />
          <form>
            <p style="font-weight: bold; text-align: left;">当前代付账号:</p>
            <input
              type="email"
              placeholder=""
              name="walletAddress"
              id="walletAddress"
              value="当前链接的钱包/只读/默认填写"
              v-model="walletAddress"
              readonly
            />

            <p style="font-weight: bold; text-align: left;">代付套餐类型:</p>

            <select class="custom-select" name="planId" v-model="planId">
              <option
                :value="item.id"
                v-for="(item, idx) in customSelect"
                :key="idx"
              >
                {{ item.name }} {{ item.fee }}
              </option>
            </select>

            <p style="font-weight: bold; text-align: left;">
              目标代付账号汇总:
            </p>
            <textarea
              cols="30"
              rows="8"
              placeholder="每个被代付的设备钱包地址逗号分割"
              name="targetWalletAddress"
              v-model="targetWalletAddress"
              id="targetWalletAddress"
            ></textarea>

            <el-button
              @click="batchPay"
              type="primary"
              class="btn"
              :loading="loadingBtn"
            >
              确认代付
            </el-button>

            <el-button
              @click="queryDevice"
              class="button"
              style="background-color: #17a2b8; margin: 0px; color: #fff;"
            >
              批量查询设备信息
            </el-button>
          </form>
        </div>
      </div>
    </div>
    <!-- end contact  -->

    <div class="table-page">
      <div class="container">
        <div class="wrap-title">
          <h5>目标代付账号资料:</h5>
        </div>
        <div
          class="wrap-content b-shadow"
          v-for="(item, idx) in queryDeviceList"
          :key="idx"
        >
          <table class="table table-default">
            <thead>
              <tr>
                <th>序号</th>
                <th>{{ idx + 1 }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>设备钱包地址</td>
                <td>{{ item.walletAddress }}</td>
              </tr>
              <tr>
                <td>设备主姓名</td>
                <td>{{ item.realName }}</td>
              </tr>
              <tr>
                <td>套餐截止日期</td>
                <td>{{ item.planEndTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      walletAddress: '',
      targetWalletAddress: '',
      customSelect: [],
      queryDeviceList: [],
      planId: 1,
      loadingBtn: false,
    }
  },
  components: {},
  mounted() {
    if (localStorage.getItem('never_walletAddress')) {
      this.walletAddress = localStorage.getItem('never_walletAddress')
      this.planList()
    }
  },
  methods: {
    batchPay() {
      if (!this.targetWalletAddress) {
        this.$message({
          message: '请输入目标代付账号汇总',
          type: 'warning',
        })
        return
      }

      this.$confirm(
        '请先批量查询设备信息，认真核对后再提交代付订单，您确认要代付吗?',
        '溫馨提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      ).then(() => {
        this.batchPayFn()
      })
    },

    batchPayFn() {
      this.loadingBtn = true
      this.axios
        .post(this.api.batchPay, {
          planId: this.planId,
          targetWalletAddress: this.targetWalletAddress,
          walletAddress: this.walletAddress,
        })
        .then((res) => {
          this.loadingBtn = false

          if (res.flag) {
            this.targetWalletAddress = ''
            this.planId = 1

            this.$message({
              message: '操作成功',
              type: 'warning',
            })
            this.goTo('/pay?data=' + JSON.stringify(res.data))
          } else {
            this.$message.error(res.message)
          }
        })
    },
    planList() {
      this.axios
        .get(this.api.planList, {
          params: {
            walletAddress: localStorage.getItem('never_walletAddress'),
          },
        })
        .then((res) => {
          if (res.flag) {
            this.customSelect = res.data
          }
        })
    },
    queryDevice() {
      if (!this.targetWalletAddress) {
        this.$message({
          message: '请输入目标代付账号汇总',
          type: 'warning',
        })
        return
      }
      this.axios
        .get(this.api.queryDevice, {
          params: {
            targetWalletAddress: this.targetWalletAddress,
          },
        })
        .then((res) => {
          if (res.flag) {
            this.queryDeviceList = res.data
          }
        })
    },
  },
  created() {},
  destroyed() {},
}
</script>
<style lang="less" scoped>
.btn {
  width: 100%;
  margin-bottom: 10px;
}
</style>
