<template>
  <div>
    <!-- navbar -->
    <div class="navbar">
      <div class="container">
        <div class="content-left">
          <a class="menu-link"><i class="fa fa-cog"></i></a>
        </div>
        <div class="content-center">
          <a>
            <img src="../../assets/images/new/logof.png" alt="" />
            <h1>NGIDC</h1>
          </a>
        </div>
        <div class="content-right"></div>
      </div>
    </div>
    <!-- end navbar -->
    <div class="alertBack">
      <div class="com">
        <div>提示</div>
        <div>请在"NaStation"钱包中打开dapp</div>
        <a href="https://www.nachain.org/app">下载钱包</a>
      </div>
    </div>

    <!-- sidebar -->
    <div class="side-overlay"></div>
    <div id="menu" class="panel sidebar" role="navigation">
      <div class="sidebar-header">
        <img src="images/profile.png" alt="" />
        <span>{{ walletName }}</span>
        <span>{{ walletAddress ? interceptFn(walletAddress) : "-" }}</span>
      </div>
      <ul>
        <li @click="bindwallet">
          <a>
            <i class="fa fa-sliders"></i>
            {{ walletAddress ? "断开" : "连接" }}钱包
          </a>
        </li>
        <li @click="goTo('/setPayPsw')" v-if="walletAddress">
          <a>
            <i class="fa fa-contao"></i>
            设置密码
          </a>
        </li>
        <li @click="goTo('/updateInformation')" v-if="walletAddress">
          <a>
            <i class="fa fa-cubes"></i>
            更新资料
          </a>
        </li>

        <li @click="goTo('/onlineProblem')" v-if="walletAddress">
          <a>
            <i class="fa fa-square"></i>
            提交工单
          </a>
        </li>

        <li @click="goTo('/applyVisit')" v-if="walletAddress">
          <a>
            <i class="fa fa-star"></i>
            申请参观
          </a>
        </li>
        <li @click="goTo('/deviceApply')" v-if="walletAddress">
          <a>
            <i class="fa fa-cubes"></i>
            申请兑换GBox
          </a>
        </li>
        <li @click="copySubmit(walletAddress)" v-if="walletAddress">
          <a>
            <i class="fa fa-clone"></i>
            复制钱包地址
          </a>
        </li>
        <li @click="goTo('/apply')" v-if="walletAddress">
          <a>
            <i class="fa fa-clock-o"></i>
            申请下架设备
          </a>
        </li>
        <!--        <li @click="goTo('/batch')" v-if="walletAddress">-->
        <!--          <a>-->
        <!--            <i class="fa fa-clipboard"></i>-->
        <!--            批量代付设备-->
        <!--          </a>-->
        <!--        </li>-->
      </ul>
    </div>
    <!-- end sidebar -->

    <!-- profile -->
    <div class="container">
      <div class="profile segments-page">
        <div class="alert alert-danger" role="alert" v-if="!walletAddress">
          请点击左上角的图标连接"NaStation"钱包(www.nachain.org)
        </div>

        <div class="profile-banner">
          <div class="content" @click="goTo('/updateInformation')">
            <img src="images/testimonial1.png" alt="" />
            <h4>{{ user.realname || "-" }} / {{ user.phone || "-" }}</h4>
            <div>
              <span>{{ user.club || "-" }}</span>
              <span> / </span>
              <span>{{ walletAddress ? interceptFn(walletAddress) : "-" }}</span>
            </div>
            <div>
              <span v-if="user.migrateStatus == 1">迁移红利期</span>
              <span v-else-if="user.migrateStatus == 2">迁移折腾期</span>
              <span v-else>尚未迁移</span>
            </div>
          </div>
        </div>
        <div class="content-info b-shadow">
          <div class="row setDis">
            <div>
              <ul>
                <li style="font-size: 14px; color: #00a3a9">
                  {{ user.accountStatus || "-" }}
                </li>
                <li>账户状态</li>
              </ul>
            </div>
            <div>
              <ul>
                <li style="font-size: 14px; color: #00a3a9">
                  {{ user.accountEndDate || "-" }}
                </li>
                <li>套餐截止日期</li>
              </ul>
            </div>
            <div>
              <ul>
                <li style="font-size: 14px; color: #00a3a9">
                  {{ user.accountLastPayDate || "-" }}
                </li>
                <li>最新续费日期</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end profile -->
    <!--    <div class="table-page hide">
      <div class="container">
        <div class="wrap-title">
          <h5>设备迁移：</h5>
        </div>
        <div class="wrap-content b-shadow">
          <table class="table-default qx">
            <tbody>
              <tr class="font-wold">
                <td>条目</td>
                <td>内容</td>
              </tr>
              <tr>
                <td>当前时期</td>
                <td>迁移折腾期</td>
              </tr>
              <tr>
                <td>时间区间</td>
                <td>2023/8/26 -2023/09/06</td>
              </tr>
              <tr>
                <td>红利说明</td>
                <td>
                  用户需要自行承担从设备下架、包装、运输、报关、仓储、香港物流到机房、再次上架的所有开支和暂停算力产出的损失。即一旦开始下架迁移，用户将会面临7-15个工作日左右的零产能，直到设备成功上架为止。若碰到大陆机房突发勒令停止托管服务的监管要求，可能会直接下架，损失自负，请知悉！
                </td>
              </tr>
              <tr>
                <td>申请费用</td>
                <td>
                  <p>
                    当前150U/台，{{
                      user.migrateStatus == 1 || user.migrateStatus == 2
                        ? "已充值"
                        : "未充值"
                    }}
                  </p>
                  <p
                    class="wb"
                    v-if="user.migrateStatus != 1 && user.migrateStatus != 2"
                  >
                    请务必单独转账
                  </p>
                </td>
              </tr>
              <tr>
                <td>迁移公告</td>
                <td class="look" @click="alertBackTips = true">点击查看</td>
              </tr>
              <tr>
                <td>申请迁移</td>
                <td v-if="user.postMigrateApply">已更新</td>
                <td v-else class="look" @click="goTo('/updateInformation')">
                  更新设备主信息
                </td>
              </tr>
              <tr v-if="!user.postMigrateApply">
                <td>申请寄回</td>
                <td @click="goTo('/apply')" class="look">申请下架寄回</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>-->

    <div class="table-page">
      <div class="container">
        <div class="wrap-title">
          <h5>套餐类型:</h5>
        </div>
        <div class="wrap-content b-shadow">
          <table class="table table-default typeList">
            <thead>
              <tr>
                <th>周期</th>
                <th>费用</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in planVoList" :key="idx" v-if="item.name === '托管一年'">
                <td>{{ item.name }}</td>
                <td>
                  <!-- <span class="green" v-if="item.discount">{{ item.discount }}</span> -->
                  <p>{{ item.fee }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="wrap-card">
      <div class="container">
        <div class="wrap-title">
          <h5>套餐续费:</h5>
        </div>
        <div class="card b-shadow pad">
          <div id="qrcode"></div>
          <div class="card-body text-center">
            <h5 class="card-title">套餐续费收款钱包地址(BSC网络):</h5>
            <p class="card-text">
              {{ user.payCoinAddress }}
            </p>
            <!--            <p class="card-nunber ">设备迁移费：150U 请单独转账</p>-->
            <button class="button waves-effect blue b-shadow" style="background-color: #00A3A9"
              @click="copySubmit(user.payCoinAddress)" v-if="user.payCoinAddress">
              复制钱包地址
            </button>
          </div>
        </div>

        <div class="alert alert-danger" role="alert">
          充值付费注意事项:
          <br />
          <br />
          1.
          请尽量通过个人BSC钱包App进行充值，如果您通过交易所进行充值，请注意提币手续费和精准的到账数量
          !
          <br />
          <br />
          2. 假设您选择 托管一年 的套餐，请一次性充值: 3300 USDT，请勿分开充值 !
          <br />
          <br />
          *错误的方式: 第一次转账1000 USDT ，第2次再转账 2300 USDT !
          <br />
          <br />
          3. 假设您选择 托管2年，请分别充值 2次 3300 USDT !
          <br />
          <br />
          4. 请使用 币安智能链-BNB Smart Chain (BEP20) 的USDT进行充值!
        </div>
      </div>
    </div>

    <div class="tabs-page">
      <div class="container">
        <div class="wrap-title">
          <h5>数据列表:</h5>
        </div>
        <div class="tabs b-shadow">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a href="#nav-tabs1" class="nav-item nav-link active" id="nav-tabs1-tab" data-toggle="tab" role="tab"
              aria-controls="nav-tabs1" aria-selected="true">
              续费记录
            </a>
            <a href="#nav-tabs2" class="nav-item nav-link" id="nav-tabs2-tab" data-toggle="tab" role="tab"
              aria-controls="nav-tabs2" aria-selected="false">
              充值记录
            </a>
            <a href="#nav-tabs3" class="nav-item nav-link" id="nav-tabs3-tab" data-toggle="tab" role="tab"
              aria-controls="nav-tabs3" aria-selected="false">
              下架申请
            </a>
            <a href="#nav-tabs4" class="nav-item nav-link" id="nav-tabs4-tab" data-toggle="tab" role="tab"
              aria-controls="nav-tabs4" aria-selected="false">
              代付订单
            </a>
          </div>
        </div>

        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-tabs1" role="tabpanel" aria-labelledby="nav-tabs1-tab">
            <table class="table table-default b-shadow" v-for="(item, idx) in planPayVoList" :key="idx">
              <thead>
                <tr>
                  <th>编号</th>
                  <th>{{ idx + 1 }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>套餐类型</td>
                  <td>{{ item.planName }}</td>
                </tr>
                <tr>
                  <td>套餐开始日期</td>
                  <td>{{ item.planStartDate }}</td>
                </tr>
                <tr>
                  <td>套餐结束日期</td>
                  <td>{{ item.planEndDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade show" id="nav-tabs2" role="tabpanel" aria-labelledby="nav-tabs2-tab">
            <table class="table table-default b-shadow" v-for="(item, idx) in payCoinVoList" :key="idx">
              <thead>
                <tr>
                  <th>编号</th>
                  <th>{{ idx + 1 }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>充值金额</td>
                  <td>{{ item.amount }}</td>
                </tr>
                <tr>
                  <td>转账地址</td>
                  <td class="setColor" @click="copySubmit(item.fromAddress)">
                    {{ interceptFn(item.fromAddress) }}
                  </td>
                </tr>
                <tr>
                  <td>交易哈希</td>
                  <td class="setColor" @click="copySubmit(item.hash)">
                    {{ interceptFn(item.hash) }}
                  </td>
                </tr>
                <tr>
                  <td>充值时间</td>
                  <td>{{ item.payTime }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="tab-pane fade show" id="nav-tabs3" role="tabpanel" aria-labelledby="nav-tabs3-tab">
            <table class="table table-default b-shadow" v-for="(item, idx) in applyList" :key="idx">
              <thead>
                <tr>
                  <th>编号</th>
                  <th>{{ idx + 1 }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>申请人员</td>
                  <td>{{ item.realName || "-" }}</td>
                </tr>
                <tr>
                  <td>申请设备</td>
                  <td class="setColor" @click="copySubmit(item.walletAddress)">
                    {{ interceptFn(item.walletAddress) || "-" }}
                  </td>
                </tr>
                <tr>
                  <td>申请原因</td>
                  <td>{{ item.reason || "-" }}</td>
                </tr>
                <tr>
                  <td>快递收件人</td>
                  <td>{{ item.receiveRealName || "-" }}</td>
                </tr>
                <tr>
                  <td>快递单号</td>
                  <td class="setColor" @click="copySubmit(item.expressOrderNumber)">
                    {{ item.expressOrderNumber || "-" }}
                  </td>
                </tr>
                <tr>
                  <td>设备状态</td>
                  <td>{{ item.offlineStatus == 1 ? "已下架" : "未下架" }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="tab-pane fade show" id="nav-tabs4" role="tabpanel" aria-labelledby="nav-tabs4-tab">
            <table class="table table-default b-shadow" v-for="(item, idx) in batchPayList" :key="idx">
              <thead>
                <tr>
                  <th>代付订单号</th>
                  <th @click="copySubmit(item.orderNumber)">
                    {{ item.orderNumber }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>代付总设备数</td>
                  <td>{{ item.amount || "-" }}</td>
                </tr>
                <tr>
                  <td>代付套餐类型</td>
                  <td>{{ item.planType || "-" }}</td>
                </tr>
                <tr>
                  <td>代付总金额</td>
                  <td class="setRed">{{ item.money || "-" }} USDT</td>
                </tr>
                <tr>
                  <td>订单状态</td>
                  <td>{{ item.statusText || "-" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="faq">
      <div class="container">
        <div class="wrap-title">
          <h5>常见问题:</h5>
        </div>

        <div id="accordionFaq" class="accordion b-shadow">
          <div class="card">
            <div id="header4" class="card-header" data-toggle="collapse" data-target="#collapse4">
              <h6 style="color: #00a3a9">1. 请问具体的续费规则是怎么样的?</h6>
            </div>
            <div id="collapse4" class="collapse show" aria-labelledby="header4" data-parent="#accordionFaq">
              <div class="card-body">
                <span>
                  答:
                  <br />
                  1.
                  根据到期时间，请提前3-7天转入费用，若未及时处理，到期后开始进入下架流程!
                  <br />
                  2.
                  若已下架，重新申请上架，需要排队7-15个工作日处理上架事宜，请知悉!
                  <br />
                  3.
                  若已下架，且并未按期续寄存托管费，超过30天将视为放弃设备，后续不再处理上架和机器托管服务，设备所在机房将会自行丢弃处理。
                </span>
              </div>
            </div>
          </div>

          <div class="card">
            <div id="header1" class="card-header" data-toggle="collapse" data-target="#collapse1">
              <h6 style="color: #00a3a9">2. 请问如何续费套餐?</h6>
            </div>
            <div id="collapse1" class="collapse" aria-labelledby="header1" data-parent="#accordionFaq">
              <div class="card-body">
                <span>
                  答:
                  <br />
                  1. 您可以选择续费的套餐类型。
                  <br />
                  2. 您需要通过支持BSC-USDT的钱包向 指定的收款地址
                  转账对应金额的USDT数量。如果转账地址或金额错误导致无法匹配您的设备，损失后果自负！
                  <br />
                  3. 耐心等待交易生效，预计1分钟，您的套餐将会生效!
                </span>
              </div>
            </div>
          </div>

          <div class="card">
            <div id="heading2" class="card-header" data-toggle="collapse" data-target="#collapse2">
              <h6 style="color: #00a3a9">
                3. 请问为什么我的钱包地址无法关联账户数据?
              </h6>
            </div>
            <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionFaq">
              <div class="card-body">
                <span>
                  答:
                  <br />
                  1. 首先确保您的NA钱包地址已绑定设备服务器。
                  <br />
                  2. 核对当前授权链接的NA钱包地址。
                  <br />
                  3. 如果上述都没有问题，请联系续费客服（微信：NA67896543NA）。
                </span>
              </div>
            </div>
          </div>

          <div class="card">
            <div id="heading3" class="card-header" data-toggle="collapse" data-target="#collapse3">
              <h6 style="color: #00a3a9">4. 请问账户可以提前续费吗</h6>
            </div>
            <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordionFaq">
              <div class="card-body">
                <span>
                  答:
                  <br />
                  可以。只要您续费对应的套餐，系统将会自动叠加您的套餐时间。请确保每一笔转账的地址和数量是正确的。
                </span>
              </div>
            </div>
          </div>

          <div class="card">
            <div id="heading4" class="card-header" data-toggle="collapse" data-target="#collapse4">
              <h6 style="color: #00a3a9">5. 请问可以申请下架寄回吗</h6>
            </div>
            <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionFaq">
              <div class="card-body">
                <span>
                  答:
                  <br />
                  可以! 请点击左上角图标, 在侧滑菜单进入申请下架页面!
                  请确保之前的费用已经结算完毕的情况下，填写申请下架寄回的快递信息:
                  设备主的钱包地址，收货人姓名，收货人电话，收货地址。
                  若有欠款未清，视为放弃设备。请客户到香港机房自提，提前做好海关的报关准备。
                </span>
              </div>
            </div>
          </div>

          <div class="card">
            <div id="heading5" class="card-header" data-toggle="collapse" data-target="#collapse5">
              <h6 style="color: #00a3a9">
                6. 请问我的账户可以代付多台服务器吗
              </h6>
            </div>
            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionFaq">
              <div class="card-body">
                <span>
                  答:
                  <br />
                  可以!
                  <br />
                  请点击左上角图标, 在侧滑菜单进入批量代付页面!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer class="segments" style="margin-top: 30px">
      <div class="container setLogo">
        <p> <img src="../../assets/images/new/logof.png" alt="" /></p>
        <h4 style="color: white">香港新一代数据中心续费系统</h4>
        <p>Copyright © All Right Reserved</p>
      </div>
    </footer>
    <!-- end footer -->
    <div class="alertBackTips" v-if="alertBackTips">
      >
      <div class="tipCom b-shadow">
        <div class="tipTitle">《香港托管到期，国庆兑换福利通知》</div>
        <div class="ovsList">
          <div>
            尊敬的 NA 设备用户：
          </div>
          <div>
            您好！
          </div>
          <div>
            首先，衷心感谢您长久以来对 NA 公链以及香港新一代数据中心的信任与支持。在此郑重通知，香港机房所提供的为期一年的托管服务将于 2024 年 9 月 25 日到期。依据香港合同法规定，若需续约，则需按照新一代公司的约定，以一年为起签期限。有托管需求的设备主可在续费系统直接进行续约操作，以享受为期一年的托管服务。
          </div>
          <div>
            此外，为回馈广大始终信任并跟随的续费用户，祝贺Gather吉隆坡数据中心在9月13号盛大开业，社区推出一项国庆兑换福利：【可享受 1:1 免费兑换Gather设备的活动】，即一台处于在线状态的 NA 设备能够兑换一台最新的 Gather 设备。（仅限续费有效期内的客户进行兑换，兑换后可拥有新设备的归属权以及三年保修服务）。
          </div>
          <div>
            具体操作方式如下：9月13号后可在续费系统提交兑换申请，并填写新的矿工钱包地址（NA 钱包）。新设备将于 2024 年 10 月 1 日在马来西亚数据中心开机运行，托管费用为每月 100U，半年起交即 600U，请务必在开机前完成缴费。
          </div>
          <div>
            温馨提示：兑换申请时间将从9月13号开始至 9 月 25 日 24:00截止，请符合条件的用户务必在规定时间内完成兑换申请，以免错失此次难得的机会。若处于未在线状态的设备也想参与免费兑换活动，可在续费系统将托管费用补齐，即可参与兑换。机会仅此一次！
          </div>
          <div>
            感谢您的支持与配合！祝大家生活愉快！
          </div>
          <div>
            <p>NA 公链共识社区</p>
            <p>2024 年 9 月 7 日</p>
          </div>
        </div>
        <div class="exchangeImmediately" @click="goTo('/deviceApply')" v-if="!user.hasDeviceApply">立刻兑换</div>
        <div class="colseBtn" @click="alertBackTips = false">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {goTo} from "@/const/common";

export default {
  name: "profile",
  computed: {
    goTo() {
      return goTo
    }
  },
  data() {
    return {
      walletAddress: "",
      walletName: "",
      user: {},
      planVoList: [],
      planPayVoList: [],
      payCoinVoList: [],
      applyList: [],
      batchPayList: [],
      alertBackTips: false,
    };
  },
  components: { QRCode },
  mounted() {
    if (localStorage.getItem("never_walletAddress")) {
      this.walletAddress = localStorage.getItem("never_walletAddress");
      this.walletName = localStorage.getItem("never_walletName");

      this.indexApi();
      this.batchPayListFn();
    }
    $(".alertBack").click(function () {
      $(".alertBack").hide();
    });

    $(".menu-link").bigSlide({
      menu: "#menu",
      side: "left",
      easyClose: true,
      menuWidth: "260px",
      afterOpen: function () {
        $("body").addClass("menu-open");
      },
      afterClose: function () {
        $("body").removeClass("menu-open");
      },
    });
  },
  methods: {
    noOpen(){
      this.$message({
          message: "即将在9月13号开放",
          type: "warning",
        });
    },
    bindwallet() {
      var self = this;
      if (this.walletAddress) {
        localStorage.removeItem("never_walletAddress");
        localStorage.removeItem("never_walletName");
        this.walletAddress = "";
        this.walletName = "";
        this.user = {};
        this.planVoList = [];
        this.planPayVoList = [];
        this.payCoinVoList = [];
        this.applyList = [];
        this.batchPayList = [];

        this.$router.go(0);
        $("body").click();

        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "walletConnectBridge",
          },
          (msg) => {
            if (msg.walletAddress) {
              localStorage.setItem("never_walletAddress", msg.walletAddress);
              self.walletAddress = localStorage.getItem("never_walletAddress");
            }
            if (msg.walletName) {
              localStorage.setItem("never_walletName", msg.walletName);
              self.walletName = localStorage.getItem("never_walletName");
            }
            self.indexApi();
            self.batchPayListFn();

            $("body").click();
            self.$router.go(0);
          }
        );
      } catch (e) {
        $(".alertBack").show();
        $("body").click();
      }
    },
    //  生成二维码
    qrcode() {
      let that = this;
      $("#qrcode").html("");
      let qrcode = new QRCode("qrcode", {
        width: 160,
        height: 160,
        text: that.user.payCoinAddress || "No Address",
      });
    },
    indexApi() {
      this.axios
        .get(this.api.indexApi, {
          params: {
            walletAddress: localStorage.getItem("never_walletAddress"),
          },
        })
        .then((res) => {
          if (res.flag) {
            this.user = res.data.user;
            res.data.planVoList.forEach((e) => {
              if (e.name == '托管三个月') {
                e.fee = '850 USDT'
                e.discount = '680 USDT'
              }
              if (e.name == '托管半年') {
                e.fee = '1680 USDT'
                e['discount'] = '1260 USDT'
              }

            })
            this.planVoList = res.data.planVoList;
            this.planPayVoList = res.data.planPayVoList;
            this.payCoinVoList = res.data.payCoinVoList;
            this.applyList = res.data.applyList;

            this.qrcode();
            if (!this.user.hasPsw) {
              this.$confirm("您尚未设置操作密码 ,请前往设置!", "操作密码", {
                confirmButtonText: "立刻设置",
                cancelButtonText: "关闭",
                type: "warning",
              })
                .then(() => {
                  this.goTo("/setPayPsw");
                })
                .catch(() => { });
            }
          }
        });
    },
    batchPayListFn() {
      this.axios
        .get(this.api.batchPayList, {
          params: {
            walletAddress: localStorage.getItem("never_walletAddress"),
          },
        })
        .then((res) => {
          if (res.flag) {
            this.batchPayList = res.data;
          }
        });
    },
  },
  created() { },
  destroyed() { },
};
</script>
<style lang="less" scoped>
.alertBack {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  height: 100%;
  z-index: 112;
  padding: 30px;

  .com {
    position: fixed;
    bottom: 20px;
    background: #fff;
    height: 200px;
    width: 90%;
    left: 5%;
    text-align: center;
    border-radius: 20px;
    padding: 20px;

    >div:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    >div:nth-child(2) {
      font-size: 14px;
      margin: 26px 0 40px;
      color: #333;
    }

    a {
      color: #fff;
      background: #00a3a9;
      border-radius: 10px;
      height: 40px;
      line-height: 40px;
      display: block;
    }
  }
}

.setColor {
  color: #00a3a9;
}

.setDis {
  display: flex;
  justify-content: space-between;

  >div {
    width: 33.3%;
    text-align: center;
  }
}

#qrcode {
  margin: 0 auto;
}

.pad {
  padding-top: 20px;
}

.setRed {
  color: red;
  font-size: 18px;
}

.qx {
  width: 100%;

  .look {
    color: #00a3a9 !important;
  }

  tbody {
    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      margin-bottom: 10px;
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 10px;

      td:nth-child(1) {
        word-break: keep-all;
      }

      td:nth-child(2) {
        max-width: 70%;

      }
    }

    tr:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

}

.alertBackTips {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .tipCom {
    background: #fff;
    width: 90%;
    margin: 0 auto;
    padding: 16px;
    border-radius: 8px;
  }

  .tipTitle {
    text-align: center;
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }

  .ovsList {
    max-height: 480px;

    overflow-y: scroll;
    margin-top: 20px;

    >div {
      color: #333;
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 1.6;
    }

    p {
      margin: 0;
    }
  }

  .colseBtn {
    border: 1px solid #333;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 16px;
  }
  .exchangeImmediately {
    border: 1px solid #00A3A9;
    background: #00A3A9;
    color: #fff;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 16px;
  }
}

.tab-content {
  background: #fff;
}

.font-wold {
  font-weight: bold;
}

.card-nunber {
  color: red;
  font-weight: bold;
}

.wb {
  color: red;

  text-align: center;
}

.typeList {
  color: #333;

  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.content-center {
  a {
    display: flex;
    align-items: center;
  }

  img {
    width: 60px;
  }
}

.setLogo {
  p {
    margin: 0;

  }

  img {
    width: 100px;
    margin: 0;
    margin-bottom: 10px;

  }
}

.green {
  color: green;
  font-weight: bold;
  font-size: 16px;
}

.bole {
  color: red;
  font-size: 16px;
  font-weight: bold;
}</style>
