import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/request/http";
import api from "@/request/api";
import { goTo, goRep, goBack, goResolve, goHref } from "@/const/common.js";
import { formatDate } from "@/util/time";
// import i18n from "@/i18n/i18n";
import './const/bridge'
// https://animate.style/
// import VConsole from "vconsole/dist/vconsole.min.js";
// let vConsole = new VConsole();

import {
  copySubmit,
  interceptFn,
} from '@/util/filters'

Vue.prototype.copySubmit = copySubmit
Vue.prototype.interceptFn = interceptFn
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import qs from 'qs'
Vue.prototype.qs = qs;
Vue.prototype.goTo = goTo;
Vue.prototype.goRep = goRep;
Vue.prototype.goBack = goBack;
Vue.prototype.goResolve = goResolve;
Vue.prototype.goHref = goHref;

Vue.prototype.api = api;
Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.formatDate = formatDate;
Vue.prototype.copySubmit = copySubmit;
Vue.prototype.themeColor = sessionStorage.getItem("themeColor");



Vue.prototype.langAll = localStorage.getItem('lang');


router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox

  document.documentElement.scrollTop = 0; // safari

  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁

  next();
});

new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App),
}).$mount("#app");
