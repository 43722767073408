

// 取整或者保留指定位数
export function roundingFn(num, length) {
  if (!length) {
    if (num) {
      if (
        num.toString().split(".")[1] &&
        num.toString().split(".")[1].indexOf("999") != -1
      ) {
        return Math.round(num);
      } else {
        return num.toString().split(".")[0];
      }
    } else {
      return num;
    }
  } else {
    if (num) {
      var e = num * Math.pow(10, length);
      e = e.toString().split(".")[0] / Math.pow(10, length);
      return e;
    }
  }
}
// 中间省略...
export function interceptFn(text) {
  if (!text) {
    return "";
  }
  var startText = text.slice(0, 6);
  var endText = text.slice(text.length - 4, text.length);
  return startText + "..." + endText;
}

// 复制
export function copySubmit(name) {
  var copyContent = document.createElement("input"); //创建一个隐藏input（重要！）
  copyContent.value = name; //拼接多个赋值
  document.body.appendChild(copyContent);
  copyContent.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  this.$message.success("复制成功");
  copyContent.remove();
}
