import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index/index";
import profile from "../views/profile/profile";
import setPayPsw from "../views/profile/setPayPsw";
import onlineProblem from "../views/profile/onlineProblem";
import applyVisit from "../views/profile/applyVisit";

import onlineProblemList from "../views/profile/onlineProblemList";
import applyVisitList from "../views/profile/applyVisitList";

import contactBack from "../views/apply/apply";
import batch from "../views/batch/batch";
import pay from "../views/pay/pay";
import updateInformation from "../views/updateInformation/updateInformation";
import deviceApply from "../views/updateInformation/deviceApply";

Vue.use(VueRouter);

const routes = [
 
  {
    path: "/",
    name: "index",
    component: index,
  },
  
  {
    path: "/profile",
    name: "profile",
    component: profile,
  },
  {
    path: "/setPayPsw",
    name: "setPayPsw", 
    component: setPayPsw,
  },
  {
    path: "/onlineProblem",
    name: "onlineProblem", 
    component: onlineProblem,
  },
  
  {
    path: "/apply",
    name: "apply",
    component: contactBack,
  },{
    path: "/batch",
    name: "batch",
    component: batch,
  },{
    path: "/pay",
    name: "pay",
    component: pay,
  },
  {
    path: "/updateInformation",
    name: "updateInformation",
    component: updateInformation,
  },
  {
    path: "/onlineProblemList",
    name: "onlineProblemList",
    component: onlineProblemList,
  },  {
    path: "/applyVisit",
    name: "applyVisit",
    component: applyVisit,
  }, {
    path: "/applyVisitList",
    name: "applyVisitList",
    component: applyVisitList,
  }, {
    path: "/deviceApply",
    name: "deviceApply",
    component: deviceApply,
  },
  
  
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
