<template>
  <div>
    <!-- navbar -->
    <div class="navbar navbar-pages">
      <div class="container">
        <div class="content">
          <h4>
            <a href="#/profile" class="link-back">
              <i class="fa fa-arrow-left"></i>
            </a>
            提交工单
          </h4>
           <a href="#/onlineProblemList" class="rightList">
              我的工单列表
            </a>
        </div>
      </div>
    </div>
    <!-- end navbar -->

    <!-- contact -->
    <div class="contact segments-page">
      <div class="container">
        <div class="contact-contents b-shadow">
          <form>
            <input
              type="text"
              placeholder="当前钱包"
              disabled
              v-model="walletAddress"
            />
            <select
              class="form-control custom-select"
              id="type"
              @change="iptpriceFn"
              v-model="type"
              name="type"
            >
              <option value="-1" hidden>工单类型</option>
              <option :value="1">账户问题</option>
              <option :value="2">上架问题</option>
              <option :value="3">充值问题</option>
              <option :value="4">迁移问题</option>
            </select>

            <input
              type="text"
              placeholder="工单标题"
              name="title"
              id="title"
              v-model="title"
              maxlength="10"
            />
            <textarea
              placeholder="工单内容"
              name="content"
              id="content"
              v-model="content"
              maxlength="500"
            />
            <input
              type="password"
              placeholder="操作密码"
              name="psw"
              id="psw"
              v-model="psw"
            />
            <el-button
              @click="applyOffline"
              type="primary"
              class="btn"
              :loading="loadingBtn"
            >
              提交
            </el-button>
          </form>
        </div>
      </div>
    </div>
   
    <!-- end contact -->
  </div>
</template>

<script>
export default {
  name: "profile",
  data() {
    return {
      walletAddress: "",
      psw: "",
      title: "",
      content: "",
      type: "-1",
      loadingBtn: false,
    };
  },
  components: {},
  mounted() {
    if (localStorage.getItem("never_walletAddress")) {
      this.walletAddress = localStorage.getItem("never_walletAddress");
    }
  },
  methods: {
  
    iptpriceFn() {},
    applyOffline() {
      // if (!this.snNumber) {
      //   this.$message({
      //     message: "请输入设备序列号",
      //     type: "warning",
      //   });
      //   return;
      // }
      if (!this.walletAddress) {
        this.$message({
          message: "请选择设备钱包地址",
          type: "warning",
        });
        return;
      }
      if (this.type==-1) {
        this.$message({
          message: "请选择工单类型",
          type: "warning",
        });
        return;
      }
      if (!this.title) {
        this.$message({
          message: "请输入工单标题",
          type: "warning",
        });
        return;
      }
      if (!this.content) {
        this.$message({
          message: "请输入工单内容",
          type: "warning",
        });
        return;
      }

      if (!this.psw) {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.psw.length < 8) {
        this.$message.error("请输入8位数以上的字符");
        return;
      }
      const check =
        /^(?=.{8})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？’‘“”~ `]).*$/;
      if (check.test(this.psw)) {
      } else {
        this.$message.error(
          "操作密码由8位以上的英文字母（大小写）、数字、特殊字符组成"
        );

        return;
      }

      this.loadingBtn = true;
      this.axios
        .post(this.api.saveTrack, {
          content: this.content,
          title: this.title,
          psw: this.psw,
          type: this.type,
          walletAddress: this.walletAddress,
        })
        .then((res) => {
          this.loadingBtn = false;

          if (res.flag) {
            this.psw = "";
            this.title = "";
            this.content = "";
            this.type = -1;
            this.$message({
              message: "操作成功",
              type: "warning",
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },

    /**
     * 判断手机号码是否正确
     */
    isValidateNoneMobile(phone) {
      const obj = {
        result: true,
        msg: "",
      };

      var isPhone = /^0\d{2,3}-?\d{7,8}$/;
      if (phone) {
        if (phone.length === 11) {
          if (isPhone.test(phone)) {
            obj.msg = "手机号码格式不正确";
            obj.result = false;
          }
        } else {
          obj.msg = "手机号码长度不为11位";
          obj.result = false;
        }

        if (isPhone.test(phone)) {
          obj.msg = "手机号码格式不正确";
          obj.result = false;
        }
      } else {
        obj.msg = "手机号码不能为空";
        obj.result = false;
      }

      return obj;
    },
  },
  created() {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
.btn {
  width: 100%;
  margin-top: 20px;
}
.rightList{
  color: #fff;
  position: absolute;
  right: 20px;
  top: 18px;
}
form select {
  font-size: 14px !important;
  width: 100%;
  background: #eee;
  color: #888;
  border: 0;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 10px;
}
form select:focus {
  border-bottom: 0 !important;
}
textarea{
  height: 230px;
}
</style>
