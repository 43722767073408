<template>
  <div class="home-walkthrough segments">
    <div class="container">
      <p class="setLogo">
        <img src="../../assets/images/new/logog.png" alt="" />
      </p>
      <h5>欢迎访问香港新一代数据中心续费系统</h5>
      <div class="walkthrough-slider owl-carousel owl-theme">
        <div class="item">
          <div class="wrap-content">
            <div class="content b-shadow">
              <div class="icon">
                <i class="fa fa-mobile b-shadow">
                  
                </i>
              </div>
              <div class="text">
                <h2>授权钱包</h2>
                <p>授权钱包，获得账户资料</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="wrap-content">
            <div class="content b-shadow">
              <div class="icon">
                <i class="fa fa-cubes b-shadow"></i>
              </div>
              <div class="text">
                <h2>续费周期</h2>
                <p>按需选择续费周期，灵活调配</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="wrap-content">
            <div class="content b-shadow">
              <div class="icon">
                <i class="fa fa-paint-brush b-shadow"></i>
              </div>
              <div class="text">
                <h2>快速便捷</h2>
                <p>自助付费，代付多机，申请下架托管</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-started">
        <a class="b-shadow" @click="goTo('/profile')">开始</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},

  mounted() {
    // walkthrough
    $(".walkthrough-slider").owlCarousel({
      items: true,
      loop: false,
      marign: 10,
    });
  },
  destroyed() {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.setLogo{
  img{
    width: 140px;
    margin-bottom: 10px;
  }
}
</style>
